<script setup lang="ts">

</script>

<template>
<div class="flex flex-wrap justify-start gap-2.5 mt-60px">
    <slot />
</div>
</template>

<style lang="scss" scoped></style>
